/**
 * = Paginations
 */
.circle-pagination {
  .page-link,
  span {
    @include display-flex();
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding: 0;
    @include border-radius($circle-radius);
  }
}

