.company-details {
    padding-left: 160px;
}

.pl-160 {
    padding-left: 160px;
}

.AutoCompleteMenu {
    width: 100%;
    max-height: 90px;
    overflow: scroll;
    text-align: center;
    color: #66799e;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0.0625rem solid #d1d7e0;
    border-radius: 0.5rem;
}

.validation-error {
    color: red;
}