.invoice-status-wrapper {
  height: 75px;
}

.disable-btn {
  background-color: gray;
  cursor: not-allowed !important;
  border-color: transparent;
  box-shadow: none;
}

.disable-btn:hover {
  background-color: gray;
  border-color: transparent;
}

/* Loading */
.css-79elbk {
  height: 100vh;
}

.spinner-grow-sm {
  margin-right: 10px;
}

.css-df17o1 {
  display: none !important;
}

// logo
.logo-wrapper {
  align-items: center;
  height: 350px;
}

.siderbar-logo {
  object-fit: cover;
  width: 100px;
  height: 100px;

  @media (max-width: 768px) {
    display: none;
  }
}

.reseller-logo {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  @media (min-width: 768px) {
    display: none;
  }
}

.logo-company-img {
  border-radius: 50%;
}

// Error message
.error-message {
  color: red;
  margin-left: 5px;
  font-size: 15px;
}

// modal
.modal-backdrop {
  z-index: 100;
}

.modal {
  z-index: 115;
}

.flag-image {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.nav-item {
  margin-left: 15px;
}

// date picker
.react-datepicker__year-wrapper {
  justify-content: space-around;
}

.react-datepicker__navigation-icon::before {
  top: 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.h-80 {
  height: 80px;
}

.h-90 {
  height: 85px !important;
}


.sidebar .multi-level .nav-link {
  padding-left: 10px;
}

@media (max-width: 900px) {
  .overflow-table {
    overflow-x: scroll;
  }
  .search-bar .form-control {
    width: 180px;
  }
  .f-direction {
    flex-direction: column;
  }
  .btn-wrapper {
    margin: 0 10px;
  }
}

@media (max-width: 700px) {
  .search-bar .form-control {
    width: 70px;
  }
}

@media print {
  .lang-btn,
  .navbar {
    display: none;
  }
  .overflow-table {
    overflow-x: hidden;
  }
  .table-header {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .details {
    display: flex;
    justify-content: space-between;
  }
  .container {
    max-width: 100% !important;
  }
}

.wrapper {
  height: fit-content !important;
  min-height: 200px;
}

.rdw-colorpicker-modal-options {
  overflow: hidden !important;
}

.rdw-colorpicker-modal {
  width: 230px !important;
  height: 250px !important;
}

.tooltip {
  inset: 0px auto auto -5px !important;
}

.tooltip-inner {
  max-width: 300px;
  padding: 5px 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.rdw-embedded-modal {
  width: 265px !important;
}

.options {
  padding: 10px 5px !important;
}

.input-group .btn {
  z-index: 10;
}

.ql-toolbar .ql-snow, .ql-container .ql-snow {
  border: 0.0625rem solid #d1d7e0 !important;
  border-radius: 10px !important;
}

.ql-container {
  min-height: 100px;
}

.ql-editor {
  height: fit-content;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px;
}

.toolbar {
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
}

.ql-container {
  min-height: 100px;
}

.ql-editor {
  height: fit-content;
}
