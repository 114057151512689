// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// Shades of grey
$white: #ffffff;
$gray-100: #f3f7fa;
$gray-200: #f5f8fb;
$gray-300: #F0F3F6;
$gray-400: #eaedf2;
$gray-500: #d1d7e0;
$gray-600: #93a5be;
$gray-700: #66799e;
$gray-800: #506690;
$gray-900: #4A5073;
$dark: #262B40;
$black: #2e3650;

// fusv-disable
$grays: (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
);
// fusv-enable

// Generic colors
$blue: #0948B3;
$indigo: #4c5680;
$purple: #8965e0;
$pink: #C96480;
$red: #FA5252;
$orange: #FF9F89;
$brown: #b9a084;
$yellow: #f5b759;
$yellow-alt: #fde9ca;
$green: #05A677;
$teal: #1E90FF;
$cyan: #63b1bd;
$soft-indigo: #f5e8ff;
$soft-green: #2CA58D;

// scss-docs-start colors-map
$colors: (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800
);
// scss-docs-end colors-map

// Color scheme
$default: #262B40;
$primary: #262B40;
$secondary: #61DAFB;
$tertiary: #1B998B;
$quaternary: #C96480;

// Series names and colors. This can be extended or customized as desired. Just add more series and colors.
$ct-series-names: (a, b, c, d, e, f, g) !default;
$ct-series-colors: (
        #1B998B,
        #17a5ce,
        #262B40,
        #f5b759,
        #C96480,
        #ffffff,
        #17a5ce,
);

// Mobile landing color scheme
$primary-app: #EBF4F6;
$secondary-app: #424AA0;

$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$gray: $gray-900;
$light: $gray-400;
$lighten: $gray-300;
$soft: $gray-200;
$dark: $dark;

// Brands colors
$facebook: #3b5999;
$twitter: #1da1f2;
$google: #DB4337;
$instagram: #e4405f;
$pinterest: #bd081c;
$youtube: #cd201f;
$slack: #3aaf85;
$dribbble: #ea4c89;
$github: #222222;
$dropbox: #1E90FF;
$twitch: #4B367C;
$paypal: #ecb32c;
$behance: #0057ff;
$reddit: #E84422;

// Brand colors
$brand-colors: ();
$brand-colors: map-merge((
        "facebook": $facebook,
        "twitter": $twitter,
        "google": $google,
        "instagram": $instagram,
        "pinterest": $pinterest,
        "youtube": $youtube,
        "slack": $slack,
        "dribbble": $dribbble,
        "dropbox": $dropbox,
        "twitch": $twitch,
        "paypal": $paypal,
        "behance": $behance,
        "reddit" : $reddit,
        "github": $github
), $brand-colors);

// Theme colors
$theme-colors: ();
$theme-colors: map-merge((
        "primary": $primary,
        "secondary": $secondary,
        "tertiary": $tertiary,
        "quaternary": $quaternary,
        "primary-app": $primary-app,
        "secondary-app": $secondary-app,
        "light": $light,
        "lighten": $lighten,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "white": $white,
        "gray": $gray,
        "indigo": $indigo,
        "dark": $dark,
        "soft": $soft,
        "black": $black,
        "blue": $blue,
        "pink": $pink,
        "cyan": $cyan,
        "purple": $purple,
        "soft-indigo": $soft-indigo,
        "soft-green": $soft-green,
        "gray-100": $gray-100,
        "gray-200": $gray-200,
        "gray-300": $gray-300,
        "gray-400": $gray-400,
        "gray-500": $gray-500,
        "gray-600": $gray-600,
        "gray-700": $gray-700,
        "gray-800": $gray-800,
        "facebook": $facebook,
        "twitter": $twitter,
        "google": $google,
        "instagram": $instagram,
        "pinterest": $pinterest,
        "youtube": $youtube,
        "slack": $slack,
        "dribbble": $dribbble,
        "dropbox": $dropbox,
        "twitch": $twitch,
        "paypal": $paypal,
        "behance": $behance,
        "reddit" : $reddit,
        "github": $github
), $theme-colors);


// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $dark;
$color-contrast-light: $white;

// fusv-disable
$blue-100: tint-color($blue, 8);
$blue-200: tint-color($blue, 6);
$blue-300: tint-color($blue, 4);
$blue-400: tint-color($blue, 2);
$blue-500: $blue;
$blue-600: shade-color($blue, 2);
$blue-700: shade-color($blue, 4);
$blue-800: shade-color($blue, 6);
$blue-900: shade-color($blue, 8);

$indigo-100: tint-color($indigo, 8);
$indigo-200: tint-color($indigo, 6);
$indigo-300: tint-color($indigo, 4);
$indigo-400: tint-color($indigo, 2);
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 2);
$indigo-700: shade-color($indigo, 4);
$indigo-800: shade-color($indigo, 6);
$indigo-900: shade-color($indigo, 8);

$purple-100: tint-color($purple, 8);
$purple-200: tint-color($purple, 6);
$purple-300: tint-color($purple, 4);
$purple-400: tint-color($purple, 2);
$purple-500: $purple;
$purple-600: shade-color($purple, 2);
$purple-700: shade-color($purple, 4);
$purple-800: shade-color($purple, 6);
$purple-900: shade-color($purple, 8);

$pink-100: tint-color($pink, 8);
$pink-200: tint-color($pink, 6);
$pink-300: tint-color($pink, 4);
$pink-400: tint-color($pink, 2);
$pink-500: $pink;
$pink-600: shade-color($pink, 2);
$pink-700: shade-color($pink, 4);
$pink-800: shade-color($pink, 6);
$pink-900: shade-color($pink, 8);

$red-100: tint-color($red, 8);
$red-200: tint-color($red, 6);
$red-300: tint-color($red, 4);
$red-400: tint-color($red, 2);
$red-500: $red;
$red-600: shade-color($red, 2);
$red-700: shade-color($red, 4);
$red-800: shade-color($red, 6);
$red-900: shade-color($red, 8);

$orange-100: tint-color($orange, 8);
$orange-200: tint-color($orange, 6);
$orange-300: tint-color($orange, 4);
$orange-400: tint-color($orange, 2);
$orange-500: $orange;
$orange-600: shade-color($orange, 2);
$orange-700: shade-color($orange, 4);
$orange-800: shade-color($orange, 6);
$orange-900: shade-color($orange, 8);

$yellow-100: tint-color($yellow, 8);
$yellow-200: tint-color($yellow, 6);
$yellow-300: tint-color($yellow, 4);
$yellow-400: tint-color($yellow, 2);
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 2);
$yellow-700: shade-color($yellow, 4);
$yellow-800: shade-color($yellow, 6);
$yellow-900: shade-color($yellow, 8);

$green-100: tint-color($green, 8);
$green-200: tint-color($green, 6);
$green-300: tint-color($green, 4);
$green-400: tint-color($green, 2);
$green-500: $green;
$green-600: shade-color($green, 2);
$green-700: shade-color($green, 4);
$green-800: shade-color($green, 6);
$green-900: shade-color($green, 8);

$teal-100: tint-color($teal, 8);
$teal-200: tint-color($teal, 6);
$teal-300: tint-color($teal, 4);
$teal-400: tint-color($teal, 2);
$teal-500: $teal;
$teal-600: shade-color($teal, 2);
$teal-700: shade-color($teal, 4);
$teal-800: shade-color($teal, 6);
$teal-900: shade-color($teal, 8);

$cyan-100: tint-color($cyan, 8);
$cyan-200: tint-color($cyan, 6);
$cyan-300: tint-color($cyan, 4);
$cyan-400: tint-color($cyan, 2);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 2);
$cyan-700: shade-color($cyan, 4);
$cyan-800: shade-color($cyan, 6);
$cyan-900: shade-color($cyan, 8);
// fusv-enable

// Characters which are escaped by the escape-svg function
$escaped-characters: (
                ("<", "%3c"),
                (">", "%3e"),
                ("#", "%23"),
                ("(", "%28"),
                (")", "%29"),
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-grid-classes: true;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;
$enable-smooth-scroll: false;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0));

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer / 4,
        2: $spacer / 2,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: ($spacer * 5),
        7: ($spacer * 8),
        8: ($spacer * 10),
        9: ($spacer * 11),
        10: ($spacer * 14),
        11: ($spacer * 16),
        12: ($spacer * 20),
        'sm': ($spacer * 1),
        'md': ($spacer * 2),
        'lg': ($spacer * 4),
        'xl': ($spacer * 8)
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);

// Body
//
// Settings for the `<body>` element.

$body-bg: $soft;
$body-color: $gray-900;
$body-text-align: null;


// Links
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: null;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

$stretched-link-pseudo-element: after;
$stretched-link-z-index: 1;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1200px
);
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 1.5rem;
$grid-row-columns: 6;

$gutters: $spacers;

// Container padding

$container-padding-x: 2rem;


// Components
//
// Define common padding and border radius sizes and more.

$border-width: .0625rem;
$border-width-md: 0.125rem;
$border-width-lg: 0.25rem;
$border-width-xl: 0.375rem;
$border-color-white: $white;
$border-color: $light;

$border-radius: .5rem;
$border-radius-xl: .875rem;
$border-radius-lg: .3rem;
$border-radius-md: .2rem;
$border-radius-sm: .1rem;
$border-radius-pill: 2rem;
$circle-radius: 50%;
$organic-radius: 63% 37% 30% 70% / 50% 45% 55% 50%;

$component-active-color: $white;
$component-active-bg: $primary;
$component-active-border-color: $primary;

$component-hover-color: $gray-300;
$component-hover-bg: $gray-300;
$component-hover-border-color: $gray-300;

$rounded-pill: 50rem;

$box-shadow: 0 .5rem 1rem rgba($black, .15);
$box-shadow-sm: 0 2px 18px rgba(0, 0, 0, 0.02);
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);
$box-shadow-inset: inset 0 1px 2px rgba($black, .075);

$component-active-color: $white;
$component-active-bg: $primary;

$caret-width: .3em;
$caret-vertical-align: $caret-width * .85;
$caret-spacing: $caret-width * .85;

$transition-base: all .2s ease;
$transition-transform: transform .2s ease;
$transition-fade: opacity .15s linear;
$transition-collapse: height .35s ease;
$transition-tabs: all 0.2s;

// scss-docs-start embed-responsive-aspect-ratios
$embed-responsive-aspect-ratios: (
        "21by9": (
                x: 21,
                y: 9
        ),
        "16by9": (
                x: 16,
                y: 9
        ),
        "4by3": (
                x: 4,
                y: 3
        ),
        "1by1": (
                x: 1,
                y: 1
        )
);
// scss-docs-end embed-responsive-aspect-ratios

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// font awesome
$fontawesome-webfonts-path: '../vendor/font-awesome/webfonts';
$font-awesome-5: 'Font Awesome 5 Free';

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Nunito Sans', sans-serif;
// stylelint-enable value-keyword-case
$font-family-base: var(--bs-font-sans-serif);
$font-family-code: var(--bs-font-monospace);

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-xs: ($font-size-base * .75);
$font-size-sm: ($font-size-base * .875);
$font-size-md: ($font-size-base * 1);
$font-size-lg: ($font-size-base * 1.25);
$font-size-xl: ($font-size-base * 1.5);
$font-size-xxl: ($font-size-base * 2);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$font-weight-extreme: 800;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom: $spacer / 2;
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.3;
$headings-color: $dark;

// Display headings
$display1-size: 5rem;
$display2-size: 3.5rem;
$display3-size: 2.5rem;
$display4-size: 1.875rem;

// scss-docs-start display-headings
$display-font-sizes: (
        1: 5rem,
        2: 3.5rem,
        3: 2.5rem,
        4: 1.875rem,
        5: 1.2rem,
        6: 1rem
);

$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size,
        7: .9rem
);

$display-font-weight: $font-weight-bold;
$display-line-height: $headings-line-height;
// scss-docs-end display-headings

$paragraph-font-size: 1rem;
$paragraph-font-weight: 300;
$paragraph-line-height: 1.6;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: .875em;

$sub-sup-font-size: .75em;

$text-muted: #58677d;

$initialism-font-size: $small-font-size;

$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1.25;
$blockquote-footer-color: $gray-600;
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y: $spacer;
$hr-color: inherit;
$hr-height: $border-width;
$hr-opacity: .25;

$legend-margin-bottom: .5rem;
$legend-font-size: 1.5rem;
$legend-font-weight: null;

$mark-padding: .2em;

$dt-font-weight: $font-weight-bold;

$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: .5rem;

$mark-bg: #fcf8e3;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: .75rem;
$table-cell-padding-x: .5rem;
$table-cell-padding-y-sm: .25rem;
$table-cell-padding-x-sm: .25rem;

$table-cell-vertical-align: top;

$table-color: $body-color;
$table-bg: transparent;

$table-striped-color: $table-color;
$table-striped-bg-factor: .05;
$table-striped-bg: rgba($black, $table-striped-bg-factor);

$table-active-color: $table-color;
$table-active-bg-factor: .1;
$table-active-bg: rgba($black, $table-active-bg-factor);

$table-hover-color: $table-color;
$table-hover-bg-factor: .075;
$table-hover-bg: rgba($black, $table-hover-bg-factor);

$table-border-factor: .1;
$table-border-width: $border-width;
$table-border-color: $border-color;

$table-striped-order: odd;

$table-group-seperator-color: $light;

$table-caption-color: $text-muted;

$table-bg-level: -9;

$table-head-spacer-y: .75rem;
$table-head-spacer-x: 1rem;
$table-head-font-size: .75rem;
$table-head-text-transform: uppercase;
$table-body-font-size: $font-size-sm;

$table-variants: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
);
// scss-docs-end table-variables


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: .55rem;
$input-btn-padding-x: .75rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: .2rem;
$input-btn-focus-color-opacity: .25;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: .25rem;
$input-btn-padding-x-sm: .5rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: .5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;

$input-btn-border-width: $border-width;

$shadow-input: $box-shadow;
$shadow-input-focus: .1rem .1rem 0 rgba($gray-200, .5);


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-bold;
$btn-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: .65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125);

$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;
$btn-link-disabled-color: $gray-600;

$btn-block-spacing-y: .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-border-radius-lg: $border-radius-lg;

$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;


// Forms

$form-text-margin-top: .25rem;
$form-text-font-size: $small-font-size;
$form-text-font-style: null;
$form-text-font-weight: null;
$form-text-color: $text-muted;

$form-label-margin-bottom: .5rem;
$form-label-font-size: null;
$form-label-font-style: null;
$form-label-font-weight: null;
$form-label-color: null;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$inpu-lg-padding-y: 1rem;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;

$input-bg: $white;
$input-disabled-bg: $gray-200;
$input-disabled-border-color: null;

$input-color: $gray-700;
$input-border-color: $gray-500;
$input-border-width: $input-btn-border-width;
$input-box-shadow: $box-shadow-inset;

$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius-lg;

$input-focus-bg: $input-bg;
$input-focus-border-color: lighten($component-active-bg, 25%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $gray-600;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half: add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter: add($input-line-height * .25em, $input-padding-y / 2);

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));
$input-height-xl: calc(#{$input-line-height * 1.5em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border});

$input-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

// Custom forms

$custom-control-gutter: 1.75rem;
$custom-control-spacer-x: 1rem;
$custom-control-line-height: 1;
$custom-control-indicator-size: 1.25rem;

$custom-control-bg: $gray-400;
$custom-control-indicator-bg: $white;
$custom-control-border-width: $border-width;
$custom-control-border-color: $input-border-color;
$custom-control-box-shadow: none;


$custom-control-indicator-hover-color: $component-hover-color;
$custom-control-indicator-hover-bg: $primary;
$custom-control-indicator-hover-border-color: $component-hover-border-color;

$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-border-color: $component-active-border-color;


$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-border-color: $primary;
$custom-control-indicator-checked-disabled-bg: rgba($primary, .7);
$custom-control-indicator-border-width: 1px;

$custom-control-indicator-disabled-bg: $gray-200;
$custom-control-label-disabled-color: $gray-800;

$custom-checkbox-bg: $gray-400;
$custom-checkbox-checked-bg: $primary;
$custom-checkbox-disabled-checked-bg: rgba($primary, .7);
$custom-checkbox-indicator-border-radius: $border-radius-xl;
$custom-checkbox-indicator-border-width: $border-width-md;
$custom-checkbox-indicator-icon-checked: $font-awesome-5;
$square-checkbox-indicator-border-radius: 3px;

$custom-toggle-slider-bg: $gray-400;
$custom-toggle-slider-indicator-bg: $white;
$custom-toggle-checked-bg: $primary;
$custom-toggle-disabled-bg: $gray-200;
$custom-toggle-disabled-checked-bg: rgba($primary, .7);
$custom-toggle-border-radius: .8rem;

$custom-switch-indicator-size: calc(#{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4});

$custom-checkbox-disabled-checked-color: $gray-800;


$form-check-input-width: 1.125em;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: $form-check-input-width + .5em;
$form-check-margin-bottom: .125rem;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition: background-color .2s ease-in-out, background-position .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;

$form-check-input-active-filter: brightness(90%);

$form-check-input-bg: $body-bg;
$form-check-input-border: 1px solid $input-border-color;
$form-check-input-border-radius: .25em;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-switch-color: $gray-600;
$form-switch-width: 2.25em;
$form-switch-padding-start: $form-switch-width + .5em;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-border-radius: $form-switch-width;

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position: right center;

$form-check-inline-margin-end: 1rem;

$input-group-addon-color: $input-color;
$input-group-addon-bg: $white;
$input-group-addon-border-color: $input-border-color;

// Input groups

$input-group-addon-color: $input-placeholder-color;
$input-group-addon-bg: $input-bg;
$input-group-addon-border-color: $input-border-color;

$input-group-addon-focus-color: $input-focus-color;
$input-group-addon-focus-bg: $input-focus-bg;
$input-group-addon-focus-border-color: $input-border-color;

$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-font-family: $input-font-family;
$form-select-font-size: $input-font-size;
$form-select-height: $input-height;
$form-select-indicator-padding: 1rem; // Extra padding to account for the presence of the background-image based indicator
$form-select-font-weight: $input-font-weight;
$form-select-line-height: $input-line-height;
$form-select-color: $input-color;
$form-select-disabled-color: $gray-600;
$form-select-bg: $input-bg;
$form-select-disabled-bg: $gray-200;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 16px 12px; // In pixels because image dimensions
$form-select-indicator-color: $gray-800;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

$form-select-feedback-icon-padding-end: add(1em * .75, (2 * $form-select-padding-y * .75) + $form-select-padding-x + $form-select-indicator-padding);
$form-select-feedback-icon-position: center right ($form-select-padding-x + $form-select-indicator-padding);
$form-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half;

$form-select-border-width: $input-border-width;
$form-select-border-color: $input-border-color;
$form-select-border-radius: $border-radius;
$form-select-box-shadow: $box-shadow-inset;

$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-width: $input-focus-width;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width $input-btn-focus-color;

$form-select-padding-y-sm: $input-padding-y-sm;
$form-select-padding-x-sm: $input-padding-x-sm;
$form-select-font-size-sm: $input-font-size-sm;
$form-select-height-sm: $input-height-sm;

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-font-size-lg: $input-font-size-lg;
$form-select-height-lg: $input-height-lg;

$form-range-track-width: 100%;
$form-range-track-height: .5rem;
$form-range-track-cursor: pointer;
$form-range-track-bg: $gray-300;
$form-range-track-border-radius: 1rem;
$form-range-track-box-shadow: $box-shadow-inset;

$form-range-thumb-width: 1rem;
$form-range-thumb-height: $form-range-thumb-width;
$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-border: 0;
$form-range-thumb-border-radius: 1rem;
$form-range-thumb-box-shadow: 0 .1rem .25rem rgba($black, .1);
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow;
$form-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: lighten($component-active-bg, 35%);
$form-range-thumb-disabled-bg: $gray-500;
$form-range-thumb-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-file-height: $input-height;
$form-file-focus-border-color: $input-focus-border-color;
$form-file-focus-box-shadow: $input-focus-box-shadow;
$form-file-disabled-bg: $input-disabled-bg;
$form-file-disabled-border-color: $input-disabled-border-color;

$form-file-padding-y: $input-padding-y;
$form-file-padding-x: $input-padding-x;
$form-file-line-height: $input-line-height;
$form-file-font-family: $input-font-family;
$form-file-font-weight: $input-font-weight;
$form-file-color: $input-color;
$form-file-bg: $input-bg;
$form-file-border-width: $input-border-width;
$form-file-border-color: $input-border-color;
$form-file-border-radius: $input-border-radius;
$form-file-box-shadow: $input-box-shadow;
$form-file-button-color: $form-file-color;
$form-file-button-bg: $input-group-addon-bg;

$form-file-padding-y-sm: $input-padding-y-sm;
$form-file-padding-x-sm: $input-padding-x-sm;
$form-file-font-size-sm: $input-font-size-sm;
$form-file-height-sm: $input-height-sm;

$form-file-padding-y-lg: $input-padding-y-lg;
$form-file-padding-x-lg: $input-padding-x-lg;
$form-file-font-size-lg: $input-font-size-lg;
$form-file-height-lg: $input-height-lg;

$input-muted-bg: $gray-100;
$input-placeholder-color: $gray-800;
$input-focus-placeholder-color: $gray-800;

// Form validation

$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $form-text-font-size;
$form-feedback-font-style: $form-text-font-style;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

// scss-docs-start form-validation-states
$form-validation-states: (
        "valid": (
                "color": $form-feedback-valid-color,
                "icon": $form-feedback-icon-valid
        ),
        "invalid": (
                "color": $form-feedback-invalid-color,
                "icon": $form-feedback-icon-invalid
        )
);
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
// scss-docs-end zindex-stack

// No UI Slider
$noui-handle-width: 15px;
$noui-slider-height: 10px;
$noui-connect-bg: $secondary;
$noui-handle-bg: $light;
$noui-border-color: $gray-500;
$noui-focus-shadow: $input-btn-focus-box-shadow;
$noui-handle-border: 0;
$noui-handle-border-radius: $border-radius;
$noui-origin-border-radius: $circle-radius;


// Navs

$nav-link-padding-y: .5rem;
$nav-link-padding-x: 1rem;
$nav-link-color: $gray-800;
$nav-link-hover-color: $primary;
$nav-link-active-color: $gray-800;
$nav-link-shadow: none;
$nav-link-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $light;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $light;
$nav-tabs-link-active-border-color: $light;

$nav-pills-padding-y: .75rem;
$nav-pills-padding-x: .85rem;

$nav-pills-space-x: 1rem;
$nav-pills-border-circle: 50%;

$nav-pills-bg: $white;
$nav-pills-border-width: 1px;
$nav-pills-border-color: $light;
$nav-pills-border-radius: $border-radius;

$nav-pills-link-hover-color: $primary;
$nav-pills-link-active-color: $primary;
$nav-pills-active-border-color: lighten($dark, 20%);
$nav-pills-link-active-bg: #f8f8f8;

// Owl navs

$owl-nav-color: $gray-600;
$owl-nav-color-hover: $gray-900;
$owl-nav-font-size: $font-size-xxl;
$owl-nav-rounded: 3px;
$owl-nav-margin: 5px;
$owl-nav-padding: 4px 7px;
$owl-nav-background: $gray-400;
$owl-nav-background-hover: $gray-800;
$owl-nav-disabled-opacity: 0.5;

// Owl dots

$owl-dot-width: 10px;
$owl-dot-height: 10px;
$owl-dot-rounded: $circle-radius;
$owl-dot-margin: 5px 5px;
$owl-dot-border-width: 2px;
$owl-dot-background: $gray-400;
$owl-dot-background-active: $gray-800;


// Navbar

$navbar-padding-y: 1rem;
$navbar-padding-x: null;

$navbar-nav-link-padding-x: .8rem;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 4;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: 0;
$navbar-brand-margin-end: 1rem;

$navbar-toggler-padding-y: .4rem;
$navbar-toggler-padding-x: .6rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;
$navbar-toggler-focus-width: $btn-focus-width;
$navbar-toggler-transition: box-shadow .15s ease-in-out;

$navbar-dark-color: rgba($white, 0.9);
$navbar-dark-hover-color: rgba($white, 1);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, .9);
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: rgba($white, .1);

$navbar-light-color: rgba($black, .9);
$navbar-light-hover-color: rgba($black, 1);
$navbar-light-active-color: rgba($black, 1);
$navbar-light-disabled-color: rgba($black, .9);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($black, .1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem;
$dropdown-padding-y: .5rem;
$dropdown-spacer: .125rem;
$dropdown-font-size: 1rem;
$dropdown-item-font-weight: $font-weight-light;
$dropdown-item-hover-color: $gray-900;

$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: $light;
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg: $gray-200;
$dropdown-divider-margin-y: $spacer / 2;
$dropdown-box-shadow: $box-shadow;

$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $gray-200;

$dropdown-link-active-color: $gray-900;
$dropdown-link-active-bg: $gray-400;

$dropdown-link-disabled-color: $gray-600;

$dropdown-item-padding-y: $spacer / 4;
$dropdown-item-padding-x: $spacer;

$dropdown-header-color: $gray-900;
$dropdown-header-font-weight: $font-weight-bold;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x;


// Pagination

$pagination-padding-y: .375rem;
$pagination-padding-x: .75rem;
$pagination-padding-y-sm: .25rem;
$pagination-padding-x-sm: .5rem;
$pagination-padding-y-lg: .75rem;
$pagination-padding-x-lg: 1.5rem;

$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-radius: $border-radius;
$pagination-margin-start: -$pagination-border-width;
$pagination-border-color: $light;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-300;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;


// Cards

$card-spacer-y: 1.25rem;
$card-spacer-x: 1.5rem;
$card-social-padding: .25rem .375rem;
$card-title-spacer-y: .875rem;
$card-border-width: $border-width;
$card-border-radius: $border-radius;
$card-border-color: rgba($black, .125);
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: 1.25rem;
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: rgba($black, .03);
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: $white;

$card-img-overlay-padding: $spacer;

$card-group-margin: $grid-gutter-width / 2;
$transition-bezier-card: cubic-bezier(0.34, 1.45, 0.7, 1);

//Timelines
$timeline-font-size: $font-size-xxl;
$timeline-font-color: $body-color;
$timeline-border-color: $light;
$timeline-border-color-dark: $gray-500;
$timeline-background-color: $white;
$timeline-line-height: 3;
$timeline-border-width: $border-width;
$timeline-circle-radius: 50%;
$timeline-spacer-y: 1.25rem;
$timeline-spacer-x: 2.75rem;

// Icon
$icon-box-padding: 1rem;
$icon-shape: 4.5rem;
$icon-shape-xs: 2rem;
$icon-shape-sm: 3rem;
$icon-shape-lg: 5.5rem;
$icon-shape-img: 5.5rem;
$icon-shape-bordered: 5rem;
$icon-size: 2rem;
$icon-size-xs: .875rem;
$icon-size-sm: 1.25rem;
$icon-size-md: 1.875rem;
$icon-size-lg: 2.75rem;
$icon-size-xl: 4.5rem;


// Steps
$step-number-size: 12rem;
$step-shape-size: 7rem;
$step-border-width: $border-width-md;
$step-icon-size: $icon-size-xl;

// Tooltips

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $dark;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: .99;
$tooltip-padding-y: .75rem;
$tooltip-padding-x: .55rem;
$tooltip-margin: 0;

$tooltip-arrow-width: .8rem;
$tooltip-arrow-height: .4rem;
$tooltip-arrow-color: $tooltip-bg;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: null;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;


// Popovers

$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: rgba($black, .2);
$popover-border-radius: $border-radius-lg;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width);
$popover-box-shadow: $box-shadow;

$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: .5rem;
$popover-header-padding-x: $spacer;

$popover-body-color: $body-color;
$popover-body-padding-y: $spacer;
$popover-body-padding-x: $spacer;

$popover-arrow-width: 1rem;
$popover-arrow-height: .5rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, .05);


// Toasts

$toast-max-width: 350px;
$toast-padding-x: .75rem;
$toast-padding-y: .25rem;
$toast-font-size: .875rem;
$toast-color: null;
$toast-background-color: rgba($white, .85);
$toast-border-width: 1px;
$toast-border-color: rgba(0, 0, 0, .1);
$toast-border-radius: $border-radius;
$toast-box-shadow: $box-shadow;

$toast-header-color: $gray-600;
$toast-header-background-color: rgba($white, .85);
$toast-header-border-color: rgba(0, 0, 0, .05);


// Badges

$badge-font-size: .75em;
$badge-font-weight: $font-weight-bolder;
$badge-padding-y: .1rem;
$badge-padding-x: .4rem;
$badge-color: $white;
$badge-border-radius: $border-radius;

$badge-pill-padding-x: .875em;
$badge-pill-border-radius: 10rem;

$badge-circle-size: 2rem;


// Modals

// Padding applied to the modal body
$modal-inner-padding: $spacer;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: .5rem;

$modal-dialog-margin: .5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: rgba($black, .2);
$modal-content-border-width: $border-width;
$modal-content-border-radius: $border-radius-lg;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs: $box-shadow-sm;
$modal-content-box-shadow-sm-up: $box-shadow;

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: .5;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-sm: 300px;
$modal-md: 500px;
$modal-lg: 800px;
$modal-xl: 1140px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform .3s ease-out;
$modal-scale-transform: scale(1.02);


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: $spacer;
$alert-padding-x: $spacer;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;
$alert-icon-font-size: 2rem;
$alert-link-color: $white;

$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 10 !default;


// Progress bars

$progress-height: 1rem;
$progress-font-size: $font-size-base * .75;
$progress-bg: $gray-200;
$progress-border-radius: $border-radius;
$progress-box-shadow: $box-shadow-inset;
$progress-bar-color: $white;
$progress-bar-bg: $primary;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width .6s ease;

$progress-xl-size: .8rem;
$progress-lg-size: .6rem;
$progress-sm-size: .2rem;


// List group

$list-group-color: null;
$list-group-bg: $white;
$list-group-border-color: rgba($black, .125);
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-sm-item-padding-y: .625rem;
$list-group-sm-item-padding-x: .875rem;

$list-group-item-padding-y: $spacer / 2;
$list-group-item-padding-x: $spacer;
$list-group-item-bg-level: -9;
$list-group-item-color-level: 6;
$list-group-item-border-radius: $border-radius;

$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;

// Image thumbnails

$thumbnail-padding: .25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: $gray-300;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: $box-shadow-sm;


// Figures

$figure-caption-font-size: $small-font-size;
$figure-caption-color: $gray-600;


// Breadcrumbs

$breadcrumb-font-size: null;
$breadcrumb-padding-y: $spacer / 2;
$breadcrumb-padding-x: $spacer;
$breadcrumb-item-padding-x: .5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-bg: $gray-200;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: quote("/");
$breadcrumb-border-radius: $border-radius;

$breadcrumb-active-color: $white;
$breadcrumb-divider-color: $gray-700;
$breadcrumb-item-color: $gray-700;
$breadcrumb-font-weight: $font-weight-normal;
$breadcrumb-active-font-weight: $font-weight-normal;
$breadcrumb-item-light-color: $white;
$breadcrumb-divider-light-color: $white;

// Carousel

$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: .5;
$carousel-control-hover-opacity: .9;
$carousel-control-transition: opacity .15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-opacity: .5;
$carousel-indicator-active-bg: $white;
$carousel-indicator-active-opacity: 1;
$carousel-indicator-transition: opacity .6s ease;

$carousel-caption-width: 70%;
$carousel-caption-color: $white;
$carousel-caption-padding-y: 1.25rem;
$carousel-caption-spacer: 1.25rem;

$carousel-control-icon-width: 20px;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");

$carousel-transition-duration: .6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


// Spinners

$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-border-width: .25em;
$spinner-animation-speed: .75s;

$spinner-width-sm: 1.2rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: .2em;

// Datepicker

$datepicker-border-radius: $border-radius-sm;
$datepicker-dropdown-padding: 20px 22px;

$datepicker-cell-transition: $transition-base;
$datepicker-cell-hover-background: lighten($gray-400, 55%);
$datepicker-cell-border-radius: 50%;
$datepicker-cell-width: 36px;
$datepicker-cell-height: 36px;

$datepicker-disabled-cell-color: $gray-300;
$datepicker-disabled-old-new-color: $gray-500;

$datepicker-header-cell-border-radius: $border-radius-sm;

$datepicker-active-color: $white;
$datepicker-active-background: $secondary;
$datepicker-active-box-shadow: none;

$datepicker-range-background: $secondary;
$datepicker-range-cell-focused-background: darken($datepicker-range-background, 5%);
$datepicker-range-color: $white;
$datepicker-range-highlighted-bg: $gray-200;

$datepicker-dropdown-border: lighten($gray-400, 40%);
$datepicker-dropdown-bg: $white;
$datepicker-highlighted-bg: $datepicker-active-background;


// Close

$close-bg: transparent;
$close-hover-bg: transparent;
$close-color: rgba(0, 0, 0, .6);
$close-hover-color: rgba(0, 0, 0, .9);
$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-color: $black;
$close-text-shadow: 0 1px 0 $white;


// Code

$code-font-size: $small-font-size;
$code-color: $blue;

$kbd-padding-y: .2rem;
$kbd-padding-x: .4rem;
$kbd-font-size: $code-font-size;
$kbd-color: $white;
$kbd-bg: $gray-900;

$pre-color: null;

// Avatars
$avatar-sm-y: 1.5rem;
$avatar-sm-x: 1.5rem;

$avatar-md-y: 2rem;
$avatar-md-x: 2rem;

$avatar-lg-y: 3rem;
$avatar-lg-x: 3rem;

$user-avatar-height: 2.5rem;
$user-avatar-width: 2.5rem;

$user-avatar-height-xs: 1.5rem;
$user-avatar-width-xs: 1.5rem;

$user-avatar-height-lg: 3.5rem;
$user-avatar-width-lg: 3.5rem;

$user-avatar-height-xl: 4.5rem;
$user-avatar-width-xl: 4.5rem;

$user-avatar-height-xxl: 10rem;
$user-avatar-width-xxl: 10rem;

// Footer

$footer-link-font-size: .85rem;
$footer-bg: theme-color("secondary");
$footer-color: $gray-600;
$footer-link-color: $gray-600;
$footer-link-hover-color: $gray-700;
$footer-heading-color: $gray-600;
$footer-heading-font-size: $font-size-sm;
$padding-footer-sm: .125rem;
$padding-footer: .375rem;
$padding-footer-md: .625rem;
$padding-footer-general: .9375rem;
$padding-footer-big: 1.5rem;
