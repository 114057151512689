/**
 * = Blog cards
 */

.card-img-overlay {
  padding: 0;

  .card-footer,
  .card-header {
    background: transparent;
    border-color: $gray-700;
  }
}