.validation-error {
    color: #ff0000;
}

datalist#companies {
    position: absolute;
    max-height: 20em;
    border: 0 none;
    overflow-x: hidden;
    overflow-y: auto;
}

.container.full-height-container {
    display: flex;
    min-height: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    flex-direction: column;
    justify-content: center;
}