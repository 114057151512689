/**
 * = Images
 */
.image-xl {
  height: 20rem;

  img {
    height: 20rem;
  }
}

.image-lg {
  height: 12rem;

  img {
    height: 12rem;
  }
}

.image-md {
  height: 5.5rem;

  img {
    height: 5.5rem;
  }
}

.image-sm {
  height: 3rem;

  img {
    height: 3rem;
  }
}

.image-xs {
  height: 1.5rem;

  img {
    height: 1.5rem;
  }
}

.image-small {
  height: 1rem;

  img {
    height: 1rem;
  }
}


.img-thumbnail {
  border-width: $border-width-md;
  box-shadow: none;
}

.full-image {
  height: 100%;
}

.gallery-feed {
  img {
    width: 20%;
    margin-right: .5rem;
    margin-bottom: .5rem;
    float: left;
  }
}

.language-flag {
  width: auto;
  height: 1rem;
  margin-right: 0.4rem;
  position: relative;
  top: -2px;
}

@include media-breakpoint-up(sm) {
  .effect-img-2 {
    position: absolute;
    right: 5rem;
    top: 19%;
    z-index: 2;
    margin: 0;
  }

  .effect-img-1, .effect-img-2 {
    margin: 0 0 3rem;
    width: 350px;
    height: auto;
  }
}

@include media-breakpoint-down(md) {
  .effect-img-2 {
    right: .425rem;
    top: 0;
  }
}

.logo-img {
  //border-radius: 50%;
  height: 200px;
}

.pencil-icon {
  margin-left: -46px;
  background: #262B40;
  width: 35px;
  border-radius: 50%;
  height: 35px;
}

.pencil-icon .icon {
  color: #fff;
}